import React from "react";
import { Controls, SliderContainer, Label, Value, Slider } from "./styles";

interface SpringControlsProps {
  mass: number;
  stiffness: number;
  onMassChange: (value: number) => void;
  onStiffnessChange: (value: number) => void;
  damping: number;
  onDampingChange: (value: number) => void;
  allowDamping?: boolean;
}

export function SpringControls({
  mass,
  stiffness,
  onMassChange,
  onStiffnessChange,
  damping,
  onDampingChange,
  allowDamping,
}: SpringControlsProps) {
  return (
    <Controls>
      <SliderContainer>
        <Label>
          Mass
          <Value>{mass.toFixed(1)}</Value>
        </Label>
        <Slider
          type="range"
          min="0.1"
          max="5"
          step="0.1"
          value={mass}
          onChange={(e) => onMassChange(parseFloat(e.target.value))}
        />
      </SliderContainer>

      <SliderContainer>
        <Label>
          Stiffness
          <Value>{stiffness}</Value>
        </Label>
        <Slider
          type="range"
          min="10"
          max="500"
          step="10"
          value={stiffness}
          onChange={(e) => onStiffnessChange(parseFloat(e.target.value))}
        />
      </SliderContainer>

      {allowDamping && (
        <SliderContainer>
          <Label>
            Damping
            <Value>{damping.toFixed(1)}</Value>
          </Label>
          <Slider
            type="range"
            min="0"
            max="10"
            step="0.1"
            value={damping}
            onChange={(e) => onDampingChange(parseFloat(e.target.value))}
          />
        </SliderContainer>
      )}
    </Controls>
  );
}

import styled from "@emotion/styled";
import { motion } from "framer-motion/dist/framer-motion";

import mediaqueries from "@styles/media";

// :root {
//   --primary: #6366f1;
//   --theme-ui-colors-background: #fafafa;
//   --surface: #ffffff;
//   --text: #1f2937;
//   --theme-ui-colors-articleText: #6b7280;
// }

export const Container = styled.div`
  min-height: 100vh;
  padding: 2rem;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  max-width: 800px;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: var(--text);
`;

export const Card = styled.div`
  background: transparent;
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.05);
  padding: 2rem;
`;

export const Controls = styled.div`
  display: grid;
  gap: 3.5rem;
  margin-bottom: 2rem;

  width: 100%;
  max-width: 680px;

  b {
    font-weight: 800;
  }

  ${mediaqueries.desktop`
    max-width: 507px;
  `}

  ${mediaqueries.tablet`
    max-width: 486px;
    margin: 0 auto 25px;
  `};

  ${mediaqueries.phablet`
    padding: 0 20px;
  `};
`;

export const SliderContainer = styled.div`
  display: grid;
  gap: 0.5rem;
`;

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  color: ${(p) => p.theme.colors.articleText};
  font-family: ${(p) => p.theme.fonts.serif};
  font-size: 1.5rem;
`;

export const Value = styled.span`
  font-family: ${(p) => p.theme.fonts.monospace};
  color: #6366f1;
`;

export const Slider = styled.input`
  width: 100%;
  height: 4px;
  background: ${(p) => p.theme.colors.track};
  border-radius: 2px;
  appearance: none;
  cursor: pointer;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    background: #6366f1;
    border-radius: 50%;
    border: 2px solid white;
    cursor: pointer;
  }
`;

export const ChartContainer = styled.div`
  margin: 1rem 0;
  border-radius: 8px;
  padding: 2rem;
  overflow: hidden;
  background: ${(p) => p.theme.colors.inputBackground};
  width: 100%;
  max-width: 680px;


  ${mediaqueries.desktop`
    max-width: 507px;
  `}

  ${mediaqueries.tablet`
    max-width: 486px;
    margin: 0 auto 25px;
  `};

  ${mediaqueries.phablet`
    padding: 0 20px;
  `};
`;

export const AnimationContainer = styled.div`
  height: 16rem;
  background: ${(p) => p.theme.colors.inputBackground};
  border-radius: 8px;
  overflow: hidden;
  margin-top: 1rem;
  width: 100%;
  max-width: 680px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaqueries.desktop`
    max-width: 507px;
  `}

  ${mediaqueries.tablet`
    max-width: 486px;
    margin: 0 auto 25px;
  `};

  ${mediaqueries.phablet`
    padding: 0 20px;
  `};
`;

export const Ball = styled(motion.div)`
  cursor: pointer;
  width: 8rem;
  height: 8rem;
  background: #6366f1;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
`;

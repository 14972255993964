import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { useMotionValue } from "framer-motion/dist/framer-motion";

import mediaqueries from "@styles/media";
import { SpringControls } from "./Spring.Controls";
import { SpringBall } from "./Spring.Ball";
import { SpringGraph } from "./Spring.Graph";
import { simulateSpring } from "./Spring.simulation";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 32px;
  font-weight: 600;

  ${mediaqueries.phablet`
    font-size: 28px;
  `}
`;

interface SpringPlaygroundProps {
  allowDamping?: boolean;
}

const SpringPlayground: React.FC<SpringPlaygroundProps> = (props) => {
  const [mass, setMass] = useState(1);
  const [stiffness, setStiffness] = useState(100);
  const [damping, setDamping] = useState(1);
  const [springData, setSpringData] = useState<
    { time: number; position: number }[]
  >([]);

  const x = useMotionValue(0);

  useEffect(() => {
    const positions = simulateSpring(mass, stiffness);
    const newData = positions.map((pos, index) => ({
      time: index,
      position: pos,
    }));
    setSpringData(newData);

    x.set(100);
    setTimeout(() => x.set(0), 100);
  }, [mass, stiffness]);

  return (
    <Container {...props}>
      <SpringControls
        mass={mass}
        stiffness={stiffness}
        onMassChange={setMass}
        onStiffnessChange={setStiffness}
        damping={damping}
        onDampingChange={setDamping}
        allowDamping={props.allowDamping}
      />

      <SpringGraph data={springData} />

      <SpringBall
        stiffness={stiffness}
        mass={mass}
        damping={props.allowDamping ? damping : 0}
      />
    </Container>
  );
};

export default SpringPlayground;

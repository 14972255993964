export function simulateSpring(
  mass: number,
  stiffness: number,
  steps: number = 100
): number[] {
  const positions: number[] = [];
  let position = 100; // Initial displacement
  let velocity = 0;
  const damping = 0.5; // Damping coefficient
  const dt = 0.16; // Time step

  for (let i = 0; i < steps; i++) {
    const force = -stiffness * position; // Spring force (F = -kx)
    const dampingForce = -damping * velocity;
    const totalForce = force + dampingForce;
    const acceleration = totalForce / mass; // F = ma

    velocity += acceleration * dt;
    position += velocity * dt;
    positions.push(position);
  }

  return positions;
}

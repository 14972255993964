import React from "react";
import { LineChart, Line, ResponsiveContainer } from "recharts";
import { ChartContainer } from "./styles";

interface SpringGraphProps {
  data: { time: number; position: number }[];
}

export function SpringGraph({ data }: SpringGraphProps) {
  return (
    <ChartContainer>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={data}
          margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
        >
          <Line
            type="monotone"
            dataKey="position"
            stroke="#6366f1"
            dot={false}
            strokeWidth={3}
          />
        </LineChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}

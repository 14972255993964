import React from "react";
import { AnimationContainer, Ball } from "./styles";

export function SpringBall({
  stiffness,
  damping,
  mass,
}: {
  stiffness: number;
  damping: number;
  mass: number;
}) {
  const animationKey = `${stiffness}-${damping}-${mass}`;

  return (
    <AnimationContainer>
      <Ball
        key={animationKey}
        initial={{ x: "-200%" }}
        animate={{ x: "0%" }}
        transition={{
          type: "spring",
          stiffness,
          damping,
          mass,
        }}
      />
    </AnimationContainer>
  );
}

import React, { useState } from "react";
import { motion } from "framer-motion/dist/framer-motion";
import styled from "@emotion/styled";

import {
  buttonAnim,
  highlightContainerAnim,
  highlightAnim,
  labelAnim,
} from "./Spring.Button.anim";

import mediaqueries from "@styles/media";
import Stars from "./Spring.Button.Stars";

const SpringFinal = () => {
  const [hover, setHover] = useState<boolean>(false);

  const Container = styled.div`
    width: 100%;
    height: 28rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(p) => p.theme.colors.inputBackground};
    border-radius: 8px;
    width: 100%;
    max-width: 680px;
    margin: 0 auto;

    ${mediaqueries.desktop`
    max-width: 507px;
  `}

    ${mediaqueries.tablet`
    max-width: 486px;
    margin: 0 auto 25px;
  `};

    ${mediaqueries.phablet`
    padding: 0 20px;
  `};
  `;

  const Button = styled(motion.button)`
    position: relative;
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 1rem;
    border: none;
    padding: 1.4rem 1.8rem;
    border-radius: 5rem;
    cursor: pointer;

    svg {
      inline-size: 1.6rem;
      overflow: visible;
    }
  `;

  const HighlightContainer = styled(motion.div)`
    position: absolute;
    border-radius: inherit;
    overflow: hidden;
    inset: 0;
    padding-top: 2px;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: xor;
  `;

  const HighLight = styled(motion.div)`
    width: 200%;
    background: conic-gradient(from 0deg, transparent 0 330deg, white 360deg);
    -webkit-mask-image: linear-gradient(white 5%, transparent 20%);
    mask-image: linear-gradient(white 5%, transparent 20%);
    position: absolute;
    border-radius: inherit;
    z-index: -1;
    top: 0;
    left: 50%;
    translate: -50% -10%;
    aspect-ratio: 1;
  `;

  return (
    <Container>
      <div style={{ position: "relative" }}>
        <Button
          variants={buttonAnim}
          initial="init"
          animate={hover ? "anim" : "init"}
          whileTap="tap"
          onHoverStart={() => setHover(true)}
          onHoverEnd={() => setHover(false)}
          type="button"
          style={{ filter: `hue-rotate(${0}deg)` }}
        >
          <HighlightContainer
            data-testid="highlight"
            variants={highlightContainerAnim}
            animate={hover ? "anim" : "init"}
          >
            <HighLight variants={highlightAnim} />
          </HighlightContainer>
          <Stars hover={hover} />
          <motion.span variants={labelAnim}>Buy Now</motion.span>
        </Button>
      </div>
    </Container>
  );
};

export default SpringFinal;
